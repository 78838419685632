.price-list {
	font-size: 14px;
	line-height: 150%;
	@include media-breakpoint-up(sm) {
		font-size: 18px;
	}
	> .row {
		border-bottom: 1px solid #ddd;
		padding-top: 40px;
		padding-bottom: 40px;
		@include media-breakpoint-up(lg) {
			padding-top: 10px;
			padding-bottom: 10px;
		}
		&:last-child {
			border-bottom: none;
		}
		.row {
			border-bottom: 1px solid #eee;
			padding-top: 10px;
			padding-bottom: 10px;
			&:last-child {
				border-bottom: 0;
			}
		}
	}
	.item-group {
		display: flex;
		align-items: center;
		font-size: 24px;
		line-height: 150%;
		small {
			display: block;
			color: #999;
		}
	}
	.item-name {
	}
	.item-value {
		font-weight: 500;
		color: $primary;
		text-align: right;
	}
}
.price-list-blank {
	display: block;
	text-align: center;
	margin-top: -5em;
	padding: 120px 20px;
	background-color: red;
	position: relative;
	background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
	&:before {
		position: absolute;
		content: '';
		left: 0;
		bottom: 0;
		right: 0;
		top: 0;
		background: rgba(0,0,0,0.65);
	}

	.border-primary h2 {
		color: #fff;
	}
}