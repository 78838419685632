.example-wrap {
	.border-primary {
		margin-bottom: 80px;
	}
	.request {
		margin-top: 80px;
		@include media-breakpoint-up(md) {
			margin-top: 120px;
		}
		.request-text {
			h3 {
				font-size: 22px;
				font-weight: 400;
				line-height: 140%;
				margin-bottom: 20px;
			}
			.btn {
				display: block;
				letter-spacing: 1px;
				padding: 10px 20px;
				margin: 5px;
				height: 55px;
				line-height: 33px;
				@include media-breakpoint-up(sm) {
					display: inline-block;
				}
				span {
					font-size: 26px;
					margin-right: 5px;
					vertical-align: sub;
				}
				&.square {
					text-align: center;
					span {
						margin: 0;
					}
				}
			}
		}
	}
}
.example {
	margin-bottom: 20px;
	@include media-breakpoint-up(sm) {
		margin-bottom: 90px;
	}
	.info {
		padding: 20px 20px 30px 20px;
		@include media-breakpoint-up(sm) {
			padding: 40px;
		}
		@include media-breakpoint-up(lg) {
			padding: 40px 60px;
		}
		@include media-breakpoint-up(xl) {
			padding: 60px 80px;
		}
		.title {
			margin-bottom: 20px;
			@include media-breakpoint-up(sm) {
				margin-bottom: 40px;
			}
		}
		.hl {
			font-size: 15px;
			color: #999;
		}
		.desc {
			margin-left: 5px;
			margin-bottom: 20px;
			@include media-breakpoint-up(sm) {
				margin-bottom: 30px;
			}
		}
		.price {
			margin-left: 5px;
			font-size: 40px;
			font-weight: 600;
			line-height: 100%;
			color: $primary;
			@include media-breakpoint-up(sm) {
				font-size: 60px;
			}
			.icon-rouble {
				font-size: 65%;
			}
		}
	}
	.gallery {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		height: 300px;
		@include media-breakpoint-up(sm) {
			height: 500px;
		}
		@include media-breakpoint-up(lg) {
			height: 100%;
		}
		.img-wrap {
			flex: 1;
			border: 1px solid #ddd;
			.carousel-inner {
				height: 100%;
				.carousel-item {
					height: 100%;
					background-color: #eee;
					background-size: cover;
					background-position: center;
				}
			}
		}
		.other-image {
			display: flex;
			justify-content: left;
			flex: none;
			margin: 0 0 0 -5px;
			.img-preview {
				position: relative;
				width: 100%;
				height: 50px;
				margin: 5px 0 0 5px;
				background-color: #eee;
				background-size: cover;
				background-position: center;
				border: 1px solid #ddd;
				cursor: pointer;
				@include media-breakpoint-up(sm) {
					height: 70px;
				}
				&.active {
					&:before,
					&:after {
						content: '';
						display: block;
						position: absolute;
					}
					&:before {
						border: 3px solid $primary;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
					}
					&:after {
						border: 3px solid #fff;
						top: 3px;
						right: 3px;
						bottom: 3px;
						left: 3px;
					}
				}
			}
		}
	}
}